import { ProfileData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Admin/Form';
import params from '../../../../../params/profile/index.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceProfileUpdate: FC<
  PageProps & EspaceProps & UserProps
> = props => {
  const {
    espace,
    user,
    params: { espaceId, profilId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={profilId}
          itemPathnamePrefix={`/espaces/${espaceId}/profils/`}
          model={new ProfileData({ espace, params, espaceId: espace.id })}
          name="profile"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProfileUpdate);
